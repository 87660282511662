<template>
  <div class="pc-wrap">
    <div class="pc-content">
      <div class="article" style="max-width: none">
        <!-- <BreadCrumbs :list="crumbs" /> -->

        <!-- <img
          v-if="articleInfo.image_input && articleInfo.image_input[0]"
          :src="articleInfo.image_input[0]"
          alt=""
        /> -->
        <!-- <div class="title">{{ articleInfo.title }}</div> -->
        <!-- <div v-if="articleInfo.cid != 4" class="list fmix-align">
          <div class="label line1">{{ articleInfo.cart_name }}</div>
          <div class="item">
            <span class="iconfont"></span>{{ articleInfo.add_time }}
          </div>
        </div> -->
        <div class="conter" v-html="articleInfo.content"></div>

        <wx-open-launch-weapp
          style="width: 100vw"
          v-if="articleInfo.routine_id"
          :username="articleInfo.routine_id"
          :path="articleInfo.routine_url"
        >
          <script type="text/wxtag-template">
            <style>
              .mp-btn {width: 320px; margin: 20px auto;padding: 10px;border: 1px solid #eee;border-radius: 6px;box-sizing: border-box;background: #fff;}
              .fmix-center {display: flex; align-items: center; justify-content: center;}
              .mp-icon {width: 24px;height: 24px;margin-right: 6px;}
            </style>
            <div class="mp-btn fmix-center">
              <img class="mp-icon" src="https://img.runzehui.net/2022/07/07/99e9020220707133517322.png" alt=""/>
              <span>打开{{articleInfo.routine_name || '小程序'}}</span>
            </div>
          </script>
        </wx-open-launch-weapp>
      </div>

      <!-- <AsideNewsCate /> -->
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import AsideNewsCate from "@/components/aside/newsCate";
import Auth from "@/utils/wechat";

const crumbs_dft = [
  {
    title: "首页",
    url: "/",
  },
];

export default {
  name: "newsDetail",
  components: {
    AsideNewsCate,
  },
  data() {
    return {
      articleInfo: {},
      crumbs: [],
    };
  },
  watch: {
    $route(to) {
      if (to.name === "newsDetail") this.articleDetails();
    },
  },
  created() {
    if (Auth.isWeixin()) {
      Auth.wechat().finally(() => {
        this.articleDetails();
      });
    } else {
      this.articleDetails();
    }
  },
  methods: {
    updateTitle() {
      let title = this.articleInfo.title ? this.articleInfo.title + " - " : "";
      document.title = title + process.env.VUE_APP_NAME;
    },
    articleDetails() {
      let id = this.$route.params.id;
      request.get("article/details/" + id).then((res) => {
        let data = res.data;
        this.crumbs = [
          ...crumbs_dft,
          {
            title: data.catename,
            url: "/news/list?cid=" + data.cid,
          },
        ];
        if (data.content) {
          data.content = data.content.replace(/\<embed.*?\>/g, function (e) {
            let str = e.match(/\ src="http.*?\.mp4"/g);
            return (
              '<video controls style="display: block; width: 100%"><source' +
              str[0] +
              "></video>"
            );
          });
        }
        this.articleInfo = data;
        this.updateTitle();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  padding: 0 15px;
  font-size: 22px;
  color: #282828;
  font-weight: 700;
  margin: 20px 0 14px 0;
  line-height: 1.5;
}
.list {
  margin: 0 15px;
  padding-bottom: 20px;

  .label {
    font-size: 15px;
    height: 20px;
    border-radius: 2px;
    text-align: center;
    line-height: 20px;
    max-width: 100px;
    width: unset;
    color: #b1b2b3;
  }

  .item {
    margin-left: 18px;
    font-size: 14px;
    color: #999;
  }
}

.conter {
  padding: 0 15px;
  line-height: 1.8;
  word-wrap: break-word;
  word-break: normal;

  p,
  span {
    white-space: normal !important;
  }
}
</style>
